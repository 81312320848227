<template>
  <div>
    <!-- modal -->
    <b-modal
      hide-footer
      v-if="selectedFormData != null"
      id="modal-prevent-closing"
      scrollable
      ref="my-modal"
      :title="selectedFormData.name"
      size="xl"
      cancel-variant="outline-secondary"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- Dynamic forms -->
        <template>
          <Survey :survey="survey" />
        </template>

        <!-- <div v-if="selectedFormData.logoPosition != ``">
          <template>
            <Survey :survey="survey" />
          </template>
        </div>
        
        <div v-else>
          <v-form-render :form-json="dynamicFormDefinition" :form-data="formData" :option-data="optionData"
            ref="vFormRef">
          </v-form-render>
        </div> -->
      </form>
    </b-modal>

    <!-- Filters -->
    <list-data-table-filters
      :department-filter.sync="departmentFilter"
      :department-options="departmentOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />
    <!-- :plan-filter.sync="planFilter" -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
          >
            <label>Affiché</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Elements par page</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end pull-right">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button variant="primary" :to="{ name: 'form-list-add-new' }">
                <!-- @click="isAddNewDataSidebarActive = true" -->
                <span class="text-nowrap">Ajouter Formulaire</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDataListTable"
        class="position-relative"
        :items="fetchModuleForms"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun resultat trouvé"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveDataStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <a
              href="javascript:void(0)"
              id="toggle-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-prevent-closing
              variant="outline-primary"
              @click="goToFormCollect(data.item)"
            >
              <feather-icon
                :id="`form-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1"
              />
            </a>
            <b-tooltip
              title="Saisir/Completer les Données"
              :target="`form-row-${data.item.id}-edit-icon`"
            />

            <a href="javascript:void(0)" @click="goToFormDesigner(data.item)">
              <feather-icon
                :id="`form-row-${data.item.id}-preview-icon`"
                icon="LayoutIcon"
                size="16"
                class="mx-1"
              />
            </a>
            <b-tooltip
              title="Afficher Créateur Visuel"
              :target="`form-row-${data.item.id}-preview-icon`"
            />

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'form-view',
                  params: { id: data.item.id },
                }"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">Détails</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{
                  name: 'form-edit',
                  params: { id: data.item.id },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Modifier</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="onDeleteQuestion(data.item)"
                @refetch-data="refetchData"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} Enregistements</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDatas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";
import BCardCode from "@core/components/b-card-code";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// sweet alert
import Ripple from "vue-ripple-directive";

// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import { codeIcon } from "../../extensions/toastification/code";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";
import { $themeConfig } from "@themeConfig";
import router from "@/router";

import "survey-core/defaultV2.min.css";
import { Survey } from "survey-vue-ui";
import { Model } from "survey-core";

export default {
  components: {
    Survey,
    ListDataTableFilters,
    // ListDataTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // modals
    BModal,
    BCardCode,
    BCardText,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {

    const json = {
      title: "Send Feedback to the Team",
      description: "Need Help? Visit our support page",
      completedHtml: "<h3>Thank you for your feedback</h3>",
      pages: [
        {
          name: "page1",
          elements: [
            {
              type: "comment",
              name: "feedback",
              title: "Describe your feedback",
            },
          ],
        },
      ],
      showQuestionNumbers: "off",
      completeText: "Send",
      widthMode: "responsive",
    };

    const survey = new Model();
    survey.data = json;
    survey.onComplete.add(this.saveInputFormData);

    return {
      codeIcon,
      userData: JSON.parse(localStorage.getItem("userData")),
      currentToken: localStorage.getItem("token"),
      currentApiEndPoint: $themeConfig.app.api_endpoint,
      currentLocation: window.location.href,
      // modal
      name: "",
      nameState: null,
      submittedNames: [],
      // form design
      selectedFormData: null,
      dynamicFormDefinition: {},
      formData: {},
      optionData: {},
      selectedFormId: "",
      survey,
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  methods: {
    saveInputFormData(sender) {
      
      const results = JSON.stringify(sender.data);
      
      alert(results);

      this.survey.clear(true, true);

      // this.survey.render();
    },
    goToFormCollect(formData) {
      if (formData.form_type == "dynamic") {
        router.push({
          name: "data-collect",
          params: {
            formData: formData,
            id: formData.id,
            name: formData.name,
            description: formData.description,
          }
        });
      } else {
        window.location =
          form_designer_url +
          `?token=${currentToken}&username=${userData.username}&fullname=${userData.fullName}&role=${userData.role}&id=${formData.id}&name=${formData.name}&return_url=${currentLocation}&api_endpoint=${currentApiEndPoint}`;
      }
    },
    goToFormDesigner(formData) {
      if (formData.form_type == "dynamic") {
        router.push({
          name: "form-dynamic-designer",
          params: {
            id: formData.id,
            name: formData.name,
            description: formData.description,
          },
        });
      } else {
        window.location =
          form_designer_url +
          `?token=${currentToken}&username=${userData.username}&fullname=${userData.fullName}&role=${userData.role}&id=${formData.id}&name=${formData.name}&return_url=${currentLocation}&api_endpoint=${currentApiEndPoint}`;
      }
    },
    getSurveyJsonData() {
      return {
        elements: [
          {
            type: "text",
            name: "Nom complet",
            title: "Enter your first name:",
          },
        ],
      };
    },
    showForm(formObject) {

      console.log(this.survey);

      this.selectedFormData = formObject.form_design;

      /* this.survey.jsonObj = formObject.form_design;
       */
      const JsonDesignModel = JSON.parse(JSON.stringify(formObject.form_design));

      console.log(formObject.form_design);
      console.log("JsonDesignModel");
      console.log(JsonDesignModel);



      const dynamicJson = {
      title: "Dynamic Title",
      description: "Ddyn description",
      completedHtml: "<h3>Thank you for your feedback</h3>",
      pages: [
        {
          name: "page1",
          elements: [
            {
              type: "comment",
              name: "feedback",
              title: "Describe your feedback",
            },
          ],
        },
      ],
      showQuestionNumbers: "off",
      completeText: "Envoyer",
      widthMode: "responsive",
    };

      this.survey.data = JsonDesignModel;
      this.survey.render();
      

      /* console.log("selectedFormId");
      console.log(selectedFormId); */
      /* if (formObject.form_type != `dynamic`) {
        console.log(formObject.form_design)
      } else {
        console.log("static")
        console.log(formObject.form_design)
        this.selectedFormId = selectedFormId;
        this.dynamicFormDefinition = JSON.parse(
          selectedDynamicFormDefinition.form_design
        );
      } */
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$refs.vFormRef
        .getFormData()
        .then((formDataFromDynamicForm) => {
          // Form Validation OK

          let formData = new FormData();
          let method = "post";
          let params = "";
          this.actionName = "Sauvegarde...";
          const defaultActionText = this.actionName;

          // finalise form data
          formData.append("form_id", this.selectedFormId);
          formData.append("data", JSON.stringify(formDataFromDynamicForm));

          myApi({
            url: "form_data" + params,
            method: method,
            data: formData,
          })
            .then((response) => {
              // default action
              this.actionName = defaultActionText;
              if (response.status == 200) {
                this.$swal({
                  title: "Fin de l'opération",
                  text: response.data.message,
                  icon: "success",
                  timer: 5000,
                  showCancelButton: true,
                  confirmButtonText: "Afficher Formulaire Vide",
                  cancelButtonText: "Afficher Liste ?",
                  customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-outline-primary ml-1",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    // stay here
                    // reinit form
                    this.$refs["vFormRef"].resetForm();
                  } else if (result.dismiss === "cancel") {
                    // Hide the modal manually
                    this.$nextTick(() => {
                      this.$refs["my-modal"].toggle("#toggle-btn");
                    });
                  }
                });
              } else {
                this.actionName = defaultActionText;
                console.log(response.data.message);
                this.showToast(
                  "Erreur Survenu",
                  response.data.errors,
                  "warning",
                  "CheckIcon",
                  "top-center",
                  4000
                );
              }
            })
            .catch((error) => {
              //
              //console.log(error.response.data.message);
              this.actionName = defaultActionText;
              this.showToast(
                "Erreur Exceptionnelle Survenu",
                error.response.data.message + " " + error.response.data.errors,
                "warning",
                "CheckIcon",
                "top-center",
                5000
              );
            });
        })
        .catch((error) => {
          // Form Validation failed
          this.$message.error(error);
        });
    },
    onDeleteQuestion(form) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment supprimer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suppression]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onDelete(form);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-form";
    const { form_designer_url } = $themeConfig.app;

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onDelete = (form) => {
      store.dispatch(APP_STORE_MODULE_NAME + "/deleteModule", form.id).then(() => {
        // show confirmation notification
        refetchData();
      });
    };

    const isAddNewDataSidebarActive = ref(false);

    let departmentOptions = [
      { label: "Administration Globale", value: "adm" },
      { label: "Ressources Humaines", value: "rh" },
      { label: "Logistique", value: "log" },
      { label: "Gestion de Projet", value: "pm" },
      { label: "Partenariat", value: "pa" },
      { label: "Clinique Juridique", value: "jc" },
      { label: "Prévention | Protéction", value: "pp" },
      { label: "Finances", value: "fi" },
      { label: "Comptabilité", value: "ac" },
      { label: "Autres Services", value: "os" },
    ];

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const {
      fetchModuleForms,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      departmentFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleForms,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onDelete,

      // Filter
      avatarText,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      departmentOptions,
      statusOptions,

      // Extra Filters
      departmentFilter,
      statusFilter,

      form_designer_url,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
